import $axios from '@/utils/request'

// 获取菜单列表
export function getMenuTreeList (data) {
  const url = '/menu/tree/list'
  return $axios.fPost(url, data)
}

// 新增菜单
export function insertMenu (data) {
  const url = '/menu/insert'
  return $axios.fPost(url, data)
}

// 编辑菜单
export function updateMenu (data) {
  const url = '/menu/update'
  return $axios.fPost(url, data)
}

// 删除菜单
export function deleteMenu (data) {
  const url = '/menu/delete'
  return $axios.fGet(url, data)
}


// 操作日志列表
export function getLogsList (data) {
  const url = '/log/getLogsByList'
  return $axios.fPost(url, data)
}

