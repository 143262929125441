<template>
  <el-row class="table">
    <span class="title">操作日志</span>
    <el-row class="row-bg">
      <el-col :span="3">
      <el-select
        v-model="userId"
        filterable
        remote
        reserve-keyword
        placeholder="请输入操作人员名字"
        :remote-method="remoteMethod">
        <el-option v-for="item in staffList" :key="item.userId" :label="item.name" :value="item.userId"></el-option>
      </el-select>
      </el-col>
      <el-col :span="4" :offset="1">
      <el-date-picker
        v-model="dataPaker"
        type="daterange"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        @change="handleDateChange"
        :picker-options="pickerOptions">
      </el-date-picker>
      </el-col>
    <el-col :offset="3" :span="3">
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
    </el-col>
    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="用户" align="center"width="150">
        <template slot-scope="scope">
           <span v-if="scope.row.username != null">{{scope.row.username}}[{{scope.row.loginName}}]</span>
        </template>]
      </el-table-column>
      <el-table-column label="时间" align="center" prop=""  width="250">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | dateFormat }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Ip" align="center" prop="ip"   width="250"></el-table-column>
      <el-table-column label="操作" align="center"  width="200" prop="description"></el-table-column>
      <el-table-column label="状态" align="center" width="100">
        <template slot-scope="scope">
          <span v-text="scope.row.result" :style="'color'+':'+getStateColor(scope.row.result)"></span>
        </template>
      </el-table-column>

      <el-table-column label="操作内容"align="center" prop="content"></el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>
  import {getListEmployee } from '@/api/system/staff'
  import {getLogsList } from '@/api/system/menu'
  import { formatDate } from '@/utils/formatDate'

  export default {
    data () {
      return {
        dataList: [],
        dataPaker:[],
        beginTime:"",
        endTime:"",
        userId:"",
        staffList:[],
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 30, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
        },
        // 日期选择器配置
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick (picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '最近一个月',
              onClick (picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
              }
            }
          ]
        },
      }
    },
    created () {
      this.getLogsList()
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      this.dataPaker.push(start)
      this.dataPaker.push(end)
      this.beginTime = formatDate(start,'yyyy-MM-dd 00:00:00')
      this.endTime = formatDate(end,'yyyy-MM-dd 23:59:59')
    },
    methods: {
      getState (state) {
        switch (state) {
          case '成功':
            return '已停用'
          case '失败':
            return '已启用'
          default:
            return '未知状态'
        }
      },
      getStateColor (state) {
        switch (state) {
          case '失败':
            return '#CE433F'
          case '成功':
            return '#4DA54D'
          default:
            return '#F5C319'
        }
      },
      handleDateChange() {
        if(this.dataPaker.length != 0) {
          this.beginTime = this.dataPaker[0] + " 00:00:00"
          this.endTime = this.dataPaker[1] + " 23:59:59"
        }
      },
      remoteMethod(query) {
        this.introducerArray = []
        if (query.trim() !== '') {
          this.listEmployee(query)
        }
      },
      async listEmployee (query) {
        const res = await getListEmployee({
          pageNum: 1,
          pageSize: 10000,
          name:query
        })
        this.staffList = res.body.list
      },
      // 获取操作日志列表
      async getLogsList () {
        if((new Date(this.endTime).getFullYear() - new Date(this.beginTime).getFullYear())*12 + (new Date(this.endTime).getMonth()-new Date(this.beginTime).getMonth()) > 6) {
          $msg('时间跨度不能大于6个月份',2)
          return false
        }
        const response = await getLogsList({
          userId:this.userId,
          beginTime:this.beginTime,
          endTime:this.endTime,
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
        })
        if (response.state == "success") {
          this.dataList = response.body.list
          this.pagination.total = response.body.total
        }
      },

      // 重置
      reset () {
        this.dataPaker = []
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        this.dataPaker.push(start)
        this.dataPaker.push(end)
        this.beginTime = formatDate(start,'yyyy-MM-dd 00:00:00')
        this.endTime = formatDate(end,'yyyy-MM-dd 23:59:59')
        this.userId = ""
      },

      // 搜索
      search () {
        this.pagination.currentPage = 1
        this.getLogsList()
      },

      // 分页
      handleCurrentChange (val) {
        this.pagination.currentPage = val
        this.getLogsList()
      },
      handleSizeChange (val) {
        this.pagination.pageSize = val
        this.getLogsList()
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../style/table.scss";
</style>
